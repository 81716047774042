<template>
  <div class="point" :style="style">
    <img src="../assets/helping-images/up.svg" alt="" v-if="point.type === 'up'" class="up">
    <img src="../assets/helping-images/down.svg" alt="" v-if="point.type === 'down'" class="down">
  </div>
</template>
<script>
export default {
  name: 'Point',
  props: {
    point: {
      type: Object,
      required: true,
      default() {
        return {
          id: 1001,
          top: 0,
          left: 0,
          spot: 0,
          type: 'up',
          cameraRotation: -0.5,
          vector3: {
            x: 4635.857290081827,
            y: 97.94906301990757,
            z: 1859.6636132552449,
          },
        };
      },
    },
  },
  emits: ['click'],
  computed: {
    style() {
      return `top: ${this.point.top}px; left: ${this.point.left}px;`;
    },
  },
};
</script>
<style lang="stylus" scoped>
@import "../assets/styles/point.styl"
</style>
<style lang="stylus">
@media (max-width: 1280px)
  .point .dot .text br
    display none
</style>
