<template>
  <div class="hint">
    <div class="background" @click="$emit('click')" />
    <div class="form-container">
      <img src="../assets/helping-images/close.svg"
           alt="" @click="$emit('click')" class="close">
      <div class="title" v-html="hint.name" />
      <div class="description" v-html="hint.description" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Hint',
  props: {
    hint: {
      type: Object,
      required: true,
      default() {
        return {
          name: '',
          description: '',
        };
      },
    },
  },
  emits: ['click'],
};
</script>
<style scoped lang="stylus">
@import "../assets/styles/hint.styl"
</style>
