<template>
  <Babylon />
</template>

<script>
import Babylon from './components/babylon.vue';

export default {
  name: 'App',
  components: {
    Babylon,
  },
};
</script>

<style lang="stylus">
@import './assets/styles/style.styl'
</style>
