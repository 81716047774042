const resolutions = [
  {
    resolution: 640,
    prefix: 'sm-',
    jpegOptions: { quality: 60 },
    webpOptions: { quality: 60 },
    avifOptions: { quality: 60 },
  }, {
    resolution: 768,
    prefix: 'md-',
    jpegOptions: { quality: 60 },
    webpOptions: { quality: 60 },
    avifOptions: { quality: 60 },
  }, {
    resolution: 1024,
    prefix: 'lg-',
    jpegOptions: { quality: 60 },
    webpOptions: { quality: 60 },
    avifOptions: { quality: 60 },
  }, {
    resolution: 1440,
    prefix: 'xl-',
    jpegOptions: { quality: 80 },
    webpOptions: { quality: 80 },
    avifOptions: { quality: 60 },
  }, {
    resolution: 2048,
    prefix: 'xxl-',
    jpegOptions: { quality: 80 },
    webpOptions: { quality: 80 },
    avifOptions: { quality: 60 },
  }];
const numberOfVariations = 1;

module.exports = { resolutions, numberOfVariations };
